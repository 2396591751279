<template>
  <div class="field">
    <field-label v-if="label"
                 v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="file is-fullwidth" :class="[{ 'has-name': showFilename }, controlClass]">
      <label class="file-label">
        <input class="file-input" type="file" @change="select" :multiple="multiple">
        <span class="file-cta">
          <icon v-if="icon" :icon="icon" :type="iconType" :class="iconClass"/>
          <span class="file-label" v-text="buttonText"></span>
        </span>
        <span v-if="showFilename" class="file-name" v-text="getFilename"></span>
      </label>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'

export default {

  mixins: [
    props.classes,
    props.describable,
    props.disabled,
    props.error,
    props.hintable,
    props.id,
    props.label,
    props.name,
    props.required,
    props.working,

    computed.hasError,
  ],

  props: {
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean | String,
      default: false,
    },
    iconType: {
      type: String,
      default: 'fas',
    },
    iconClass: {
      type: Boolean | String,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Select File…'
    },
    showFilename: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'No file selected'
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    files: FileList,
  }),

  computed: {
    controlClass() {
      return [{
        'is-loading': this.working
      }, this.classes]
    },
    getFilename() {
      if (this.files.length) {
        return (this.multiple) ? `${this.files.length} selected` : this.files[0].name
      }
      return this.placeholder
    }
  },

  methods: {
    select(event) {
      this.files = event.target.files
      this.$emit('select', (this.multiple) ? this.files : this.files[0])
    }
  }

}
</script>

<style>
.file.is-fullwidth .file-cta .file-label {
  width: initial;
}

.file.is-boxed.has-name .file-name {
  text-align: center;
}

input.file-input {
  visibility: hidden;
}
</style>
