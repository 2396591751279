<template>
  <button
    :disabled="working"
    :class="{ 'is-loading': working, 'badge': badge }"
    :data-badge="badge"
    :type="type"
    @click.prevent="emit"
    class="button">
    <icon
      v-if="leftIcon"
      :class="iconClass"
      :pack="leftIconPack"
      :icon="leftIcon"
      :type="leftIconType"
    />
    <slot v-if="!leftIcon && !rightIcon"/>
    <span v-else><slot/></span>
    <icon
      v-if="(hasDropdown && dropdownTrigger.pack) || rightIcon"
      :class="iconClass"
      :pack="hasDropdown ? dropdownTrigger.pack || rightIconPack : rightIconPack"
      :icon="hasDropdown ? dropdownTrigger.icon || rightIcon : rightIcon"
      :type="hasDropdown ? dropdownIconType || rightIconType : rightIconType"
    />
  </button>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.badge,
    props.dropable,
    props.iconable,
    props.type,
    props.working,
  ],

  props: {
    dropdownIconPack: {
      default: 'fa',
    },
    dropdownIcon: {
      default:'angle-down'
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  computed: {
    dropdownTrigger() {
      return {
        pack: this.dropdownIconPack,
        icon: this.dropdownIcon
      }
    }
  },

  methods: {
    emit() {
      this.$emit('click')
    }
  },

}
</script>
