<template>
  <div>
    <div class="is-flex justify-between mt-1">
      <h2 class="label is-marginless">Predefined Comments</h2>
    </div>
    <div class="is-flex justify-between mt-1">
      <table class="table has-border-thin-grey is-fullwidth is-vcentered">
        <thead>
          <tr>
            <th v-for="heading in headings">{{heading}}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in checkPredefinedComments" :key="item.id">
            <td>
              <data-selector
                :data-tooltip="canEdit ? null : 'Check has answers'"
                :disabled="!canEdit"
                :value="item.status"
                :items="dropdownOptions"
                @input="updateRow('status', $event, item, index)">
              </data-selector>
            </td>
            <td>
              <text-input
                required
                :data-tooltip="canEdit ? null : 'Check has answers'"
                :disabled="!canEdit"
                :error="validateText(item.text)"
                :value="item.text" 
                @input="updateRow('text', $event, item, index)">
              </text-input>
            </td>
            <td class="is-narrow">
              <div class="grid has-2-columns gap-1">
                <action-button 
                  :data-tooltip="canEdit ? null : 'Check has answers'"
                  :disabled="!canEdit"
                  :class="{ invisible: isAddCommentInvisible(index) }"
                  class="is-small is-success is-rounded" 
                  left-icon="plus"
                  @click="createRow">
                  New Comment
                </action-button>
                <action-button
                  :data-tooltip="canEdit ? null : 'Check has answers'"
                  :disabled="!canEdit"
                  left-icon="trash"
                  class="is-danger is-small is-rounded"
                  @click="deleteRow(item, index)">
                  Delete
                </action-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckPredefinedComment from '../../../models/CheckPredefinedComment'

export default {

  props: {
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      headings: ['Status', 'Text', ''], // extra empty heading for the button
      dropdownOptions: [
        {
          'value': 'PASS',
          'label': 'Pass'
        },
        {
          'value': 'FAIL',
          'label': 'Fail'
        },
        {
          'value': 'N/A',
          'label': 'N/A'
        },
        {
          'value': 'ALL',
          'label': 'All'
        }
      ],
      loading: true,
    }
  },

  created() {
    this.load()
  },

  methods: {
    ...mapMutations('checkPredefinedComments', [
      'setCheck',
      'addCheckPredefinedComment',
      'updateCheckPredefinedComment',
      'destroyCheckPredefinedComment'
    ]),

    load() {
      this.setCheck(this.check)
      this.$store.dispatch('checkPredefinedComments/show').then(() => {
        if (this.checkPredefinedComments.length === 0) {
          this.createRow()
        }
      }).catch((error) => {
        this.$toast.error('Predefined Comments could not be fetched. Please try again or contact support.')
      }).finally(() => {
        this.loading = false
      })
      
      
    },

    createRow() {
      let checkPredefinedComment = new CheckPredefinedComment
      checkPredefinedComment.status = 'ALL'
      checkPredefinedComment.check_id = this.check.id
      this.addCheckPredefinedComment(checkPredefinedComment)
    },

    updateRow(field, newVal, currentItem, currentIndex) {
      let checkPredefinedComment = new CheckPredefinedComment
      checkPredefinedComment.id = currentItem.id
      checkPredefinedComment.check_id = currentItem.check_id
      checkPredefinedComment.status = currentItem.status
      checkPredefinedComment.text = currentItem.text
      checkPredefinedComment[field] = newVal
      this.updateCheckPredefinedComment({payload: checkPredefinedComment, index: currentIndex})
    },

    deleteRow(item, index) {
      this.destroyCheckPredefinedComment({index: index})
      if (this.checkPredefinedComments.length === 0) {
          this.createRow()
        }
    },

    isAddCommentInvisible(index) {
      if (index === this.checkPredefinedComments.length - 1) {
        return false
      } else {
        return true
      }
    },
    /**
     * 
     * @param {string} newVal
     */
    validateText(newVal) {
      if (newVal.length > 255) {
        this.$emit('error', 'Predefined comment is too long. Maximum 255 characters.')
        return 'Predefined comment is too long. Maximum 255 characters.'
      } else {
        this.$emit('error', '')
      }
    }
  },

  computed: {
    ...mapGetters('check', [
      'check',
    ]),
    ...mapGetters('checkPredefinedComments', [
      'checkPredefinedComments',
    ]),
    
  },
}
</script>

<style>
  .invisible {
    visibility: hidden;
  }
</style>
