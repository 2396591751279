<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="field is-marginless" :class="{ 'has-addons': (selected && clearable) || ($slots.left || $slots.right) }" ref="pickerContainer">
      <div class="control" v-if="$slots.left"><slot name="left"/></div>
      <div class="control is-expanded" :class="{ 'has-icons-left': leftIcon, 'has-icons-right': rightIcon }">
        <input
          ref="pickerInput"
          data-input
          :placeholder="placeholder"
          :class="elementClass"
          :disabled="disabled"
          class="input"
          type="text">
        <icon v-if="leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :icon="leftIcon" :type="leftIconType"/>
        <icon v-if="rightIcon" :class="['is-right', iconClass]" :pack="rightIconPack" :icon="rightIcon" :type="rightIconType"/>
      </div>
      <div class="control" v-if="selected && clearable">
        <button class="button" :class="elementClass" @click="clearDate">
          <icon icon="times"/>
        </button>
      </div>
      <div class="control" v-else-if="$slots.right"><slot name="right"/></div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import flatpickr from 'flatpickr'

export default {
  mixins: [
    props.classes,
    props.describable,
    props.disabled,
    props.error,
    props.hintable,
    props.iconable,
    props.label,
    props.required,
    computed.hasError
  ],
  props: {
    value: {
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    },
    noCalendar: {
      type: Boolean,
      default: false
    },
    seconds: {
      type: Boolean,
      default: false
    },
    zuluTime: {
      type: Boolean,
      default: false
    },
    timeFormat: {
      type: String,
      default: "H:i"
    },
    dateFormat: {
      type: String,
      default: "Y-m-d"
    },
    altTimeFormat: {
      type: String,
      default: "H:i"
    },
    altDateFormat: {
      type: String,
      default: "j F Y"
    },
    defaultHour: {
      default: 12
    },
    defaultMinute: {
      default: 0
    },
    minDate: {
      default: null
    },
    maxDate: {
      default: null
    },
    mode: {
      type: String,
      default: 'single'
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data: () => ({
    selected: '',
    picker: null,
  }),

  computed: {
    elementClass() {
      return [{
        [this.errorClass]: this.hasError
      }, this.classes]
    },
    isMoment() {
      if (!this.value) return false
      return (typeof this.value === 'object') && this.value.hasOwnProperty('_isAMomentObject')
    },
    format() {
      if (this.time && !this.noCalendar) {
        return `${this.dateFormat} ${this.timeFormat}`
      }
      return (this.time) ? this.timeFormat : this.dateFormat
    },
    altFormat() {
      if (this.time && !this.noCalendar) {
        return `${this.altDateFormat} at ${this.altTimeFormat}`
      }
      return (this.time) ? this.altTimeFormat : this.altDateFormat
    },
    selectedValue() {
      return (this.isMoment) ? flatpickr.formatDate(this.value.toDate(), this.format) : this.value
    }
  },

  mounted() {
    this.picker = new flatpickr(this.$refs.pickerContainer, {
      altFormat: this.altFormat,
      altInput: Boolean(this.altDateFormat),
      altInputClass: this.$refs.pickerInput.className,
      dateFormat: this.format,
      defaultDate: this.selectedValue,
      defaultHour: this.defaultHour,
      defaultMinute: this.defaultMinute,
      enableSeconds: this.seconds,
      enableTime: this.time,
      maxDate: this.maxDate,
      minDate: this.minDate,
      mode: this.mode,
      noCalendar: this.noCalendar,
      time_24hr: this.zuluTime,
      wrap: true,
      allowInvalidPreload: true,
      onClose: (selectedDates, dateString, instance) => {
      this.emit(dateString)
      this.$emit('datepicker-closed')
    },
      onChange: (s, dateString) => {
        this.emit(dateString)
      },
    })
    this.emit(this.selectedValue)
  },

  watch: {
    value: 'updateValue'
  },

  methods: {
    updateValue(value) {
      if (value !== this.selected) {
        this.picker.setDate(this.selectedValue)
        this.emit(this.selectedValue)
      }
    },
    clearDate() {
      this.picker.clear()
      this.emit('')
    },
    emit(value) {
      this.selected = value
      this.$emit('input', this.selected)
    },
  }
}
</script>
