<template>
<div class="search-container">
  <text-input class="is-marginless" classes="is-rounded" v-model="search" :placeholder="`Add existing ${Naming.Check} to your ${Naming.Checklist} or create a new one.`">
    <action-button @click="searchChecks" slot="right" left-icon="search" class="is-rounded">Search</action-button>
  </text-input>
  <div class="results has-shadow" v-if="showResults">
    <div class="columns is-gapless is-marginless">
      <div class="column">
        <p>
          <strong v-text="(!results.length) ? 'No Results to Display.' : 'Search Results' "></strong>
        </p>
      </div>
      <div class="column has-text-right">
        <action-button class="is-small" @click="closeResults">
          <icon class="has-text-danger" icon="times"/>
        </action-button>
      </div>
    </div>
    <ul class="mt" v-if="results.length">
      <li @click="select(result)" v-for="result in results" :key="result.id">
        <a>{{ result.name }}</a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { check as backend } from '@/api'

export default {

  data: () => ({
    search: '',
    results: [],
    showResults: false
  }),

  computed: mapState('checklist', ['checklist']),

  methods: {
    closeResults() {
      this.showResults = false
      this.search = ''
      this.results = []
    },
    searchChecks() {

      if(!this.search.length) {
        this.$toast.warning('Please enter a search term')
        return
      }

      backend.search({
        search: this.search,
        exclude: this.checklist.checks.map(check => check.id)
      }, response => {
        this.showResults = true
        this.results = response.data
      }, error => {})
    },
    select(result) {
      let index = this.results.indexOf(result)
      this.results.splice(index, 1)
      if(!this.results.length) this.closeResults()
      this.$emit('selected', result)
    }
  }

}
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;

  .results {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #efefef;
    padding: 1rem;
    z-index: 99;

    li {
      border-bottom: 1px dashed #efefef;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
