<template>
  <div class="field">
    <field-label v-if="label"
                 v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="field is-marginless" :class="{ 'has-addons': $slots.left || $slots.right }">
      <div class="control" v-if="$slots.left">
        <slot name="left"/>
      </div>
      <div class="control is-expanded" :class="controlClass">
        <input
            ref="control"
            :list="fieldName"
            :class="[{ [errorClass]: hasError }, classes]"
            @paste="emitPaste"
            @keydown="emitKeydown"
            @input="emit"
            @focus="emit"
            @blur="emit"
            @keyup.enter="emitEnter"
            v-bind="{ autofocus, autocomplete, dir, disabled, id, name, placeholder, readonly, required, role, type }"
            :value="value"
            class="input">
        <icon
            v-if="leftIcon"
            :class="['is-left', iconClass]"
            :pack="leftIconPack"
            :icon="leftIcon"
            :type="leftIconType"/>
        <icon
            v-if="rightIcon && !working"
            :class="['is-right', iconClass]"
            :pack="rightIconPack"
            :icon="rightIcon"
            :type="rightIconType"/>
      </div>
      <div class="control" v-if="$slots.right">
        <slot name="right"/>
      </div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
    <datalist :id="fieldName">
      <option v-for="item in dataList" :value="item.value">{{ item.value }}</option>
    </datalist>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import debounce from 'lodash/debounce'
import {v4 as uuidv4} from 'uuid'

export default {

  mixins: [
    props.autocomplete,
    props.autofocus,
    props.classes,
    props.debouncable,
    props.describable,
    props.dir,
    props.disabled,
    props.error,
    props.hintable,
    props.iconable,
    props.id,
    props.label,
    props.name,
    props.placeholder,
    props.readonly,
    props.required,
    props.role,
    props.working,

    computed.hasError,
  ],

  props: {
    value: {
      type: Number | String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    },
    dataList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      emitDebounce: debounce(this.emitEvent, this.debounce, this.debounceOptions),
      fieldName: uuidv4(),
    }
  },

  computed: {
    controlClass() {
      return [{
        'has-icons-left': this.leftIcon,
        'has-icons-right': this.rightIcon,
        'is-loading': this.working
      }, this.classes]
    },
  },

  methods: {
    emitEvent(event) {
      this.$emit(event.type, event.target.value)
    },
    emit(event) {
      let method = this.debounce && event.type === 'input' ? 'emitDebounce' : 'emitEvent'
      this[method](event)
    },
    emitKeydown(event) {
      this.$emit('keydown', event);
    },
    emitPaste(event) {
      this.$emit('paste', event);
    },
    emitEnter(event) {
      this.$emit('enter', event.target.value)
    },
    focus() {
      this.$refs.control.focus()
    }
  }
}
</script>
