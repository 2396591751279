<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="field is-marginless" :class="{ 'has-addons': $slots.left || reveal }">
      <div class="control" v-if="$slots.left"><slot name="left"/></div>
      <div class="control is-expanded" :class="controlClass">
        <input ref="control"
          :type="revealed ? 'text' : 'password'"
          :class="[{ [errorClass]: hasError }, classes]"
          v-bind="{ autofocus, disabled, id, name, placeholder, readonly, required, value }"
          @input="emit"
          class="input">
        <span class="icon is-left" v-if="meter">
          <svg class="password-meter" viewBox="0 0 36 36" :style="{ height: '1em', width: '1em' }">
            <g>
              <g class="rings">
                <path stroke-dasharray="100, 100" :d="meterPath" />
                <path :class="`is-${score}`" :stroke-dasharray="`${score}, 100`" :d="meterPath"/>
              </g>
              <path class="checkmark" :class="{ 'is-visible': rawScore >= secureThreshold }" fill-rule="evenodd" clip-rule="evenodd" d="M14.0555 24.3983C14.0567 24.3995 14.0579 24.4007 14.0591 24.4019C14.2055 24.5484 14.3747 24.6582 14.5553 24.7315C15.097 24.9511 15.7411 24.8413 16.1804 24.4019C16.1804 24.4019 16.1804 24.4019 16.1804 24.4019C16.1814 24.401 16.1823 24.4 16.1833 24.3991L26.093 14.4894C26.6788 13.9036 26.6788 12.9538 26.093 12.3681C25.5072 11.7823 24.5574 11.7823 23.9717 12.3681L15.1197 21.22L12.245 18.3452C11.6592 17.7594 10.7095 17.7594 10.1237 18.3452C9.53788 18.931 9.53788 19.8807 10.1237 20.4665L14.0555 24.3983Z" fill="black"/>
            </g>
          </svg>
        </span>
        <icon v-else-if="leftIcon"
          :class="['is-left', iconClass]"
          :pack="leftIconPack"
          :icon="leftIcon"
          :type="leftIconType"/>
        <icon
          v-if="rightIcon && !working"
          :class="['is-right', iconClass]"
          :pack="rightIconPack"
          :icon="rightIcon"
          :type="rightIconType"/>
      </div>
      <div class="control" v-if="reveal">
        <action-button
          type="button"
          :class="revealClass"
          @click="revealToggle"
          @mousedown.left.native="revealHold"
          @mouseup.left.native="revealHold">
          <div class="control" v-if="$slots.reveal"><slot name="reveal"/></div>
          <icon v-else :icon="revealIcon"/>
        </action-button>
      </div>
      <div class="control" v-if="$slots.right"><slot name="right"/></div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import debounce from 'lodash/debounce'

export default {

  mixins: [
    props.autofocus,
    props.classes,
    props.debouncable,
    props.describable,
    props.disabled,
    props.error,
    props.hintable,
    props.iconable,
    props.id,
    props.label,
    props.name,
    props.placeholder,
    props.readonly,
    props.required,
    props.working,

    computed.hasError,
  ],

  props: {
    value: {
      type: String,
      default: '',
    },
    reveal: {
      type: Boolean | String,
      default: false,
      validator: value => [false, 'hold', 'toggle'].includes(value)
    },
    revealClass: {
      type: Array | Boolean | Object | String,
      default: false,
    },
    meter: {
      type: Boolean,
      default: false
    },
    meterInput: {
      type: Array,
      default: () => ([])
    },
    secureThreshold: {
      type: Number,
      default: 3,
      validator: value => [1, 2, 3, 4].includes(value)
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data: () => ({
    revealed: false,
    rawScore: 0,
    score: 0,
    meterPath: 'M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
  }),

  computed: {
    controlClass() {
      return [{
        'has-icons-left': this.leftIcon || this.meter,
        'has-icons-right': this.rightIcon,
        'is-loading': this.working
      }, this.classes]
    },
    revealIcon() {
      let pack = this.$vueBulma.icons.pack

      let show = {
        fa: 'eye',
        zondicons: 'view-show',
        bytesize: 'eye',
        heroicons: 'view',
        feather: 'eye'
      }[pack]

      let hide = {
        fa: 'eye-slash',
        zondicons: 'view-hide',
        bytesize: 'eye',
        heroicons: 'view',
        feather: 'eye-off'
      }[pack]

      return this.revealed ? show : hide
    }
  },

  beforeMount() {
    if (this.meter) {
      let script = document.createElement('script')
      script.src = 'https://unpkg.com/zxcvbn@4.4.2/dist/zxcvbn.js'
      document.head.appendChild(script)
    }
  },

  methods: {
    emit(event) {
      if (this.meter) this.updateMeter(event.target.value)
      this.$emit('input', event.target.value)
    },
    revealToggle() {
      if (this.reveal === 'toggle') this.revealed = !this.revealed
      this.focus()
    },
    revealHold(event) {
      if (this.reveal === 'hold') {
        this.revealed = event.type === 'mousedown'
      }
    },
    updateMeter(password) {
      let meterResult = zxcvbn(password, this.meterInput)
      this.rawScore = meterResult.score
      this.score = meterResult.score * 25
      let { suggestions, warning } = meterResult.feedback
      this.$emit('feedback', {
        suggestions,
        warning,
        score: meterResult.score,
        secure: meterResult.score >= this.secureThreshold
      })
    },
    focus() {
      this.$refs.control.focus()
    }
  }

}
</script>
