<template>
  <label v-if="Boolean($slots.default)"
    :for="field || false"
    class="label is-field-label"
    :class="{ 'has-hint': hint, 'has-description': description }">
    <div class="main-label">
      <span><slot/></span>
      <span
        v-if="required"
        :class="requiredClass">
        {{ requiredText }}
      </span>
    </div>
    <icon v-if="hint"
      :icon="hintIconProps.icon"
      :type="hintIconProps.type"
      :pack="hintIconProps.pack"
      :class="['is-small has-tooltip-left has-tooltip-multiline', hintClass]"
      :data-tooltip="hint"/>
  </label>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.required,
    props.describable,
    props.hintable,
  ],

  props: {
    field: {
      type: String,
      default: '',
    },
    hintIconPack: {
      type: String,
      default: 'fa',
    },
    hintIconType: {
      type: String,
      default: 'fas',
    },
    hintIcon: {
      type: String,
      default: 'info-circle',
    },
  },

  computed: {
    hintIconProps() {
      return {
        icon: this.hintIcon,
        type: this.hintIconType,
        pack: this.hintIconPack,
      }
    }
  }

}
</script>
