<template>
  <section class="markdown-editor" :class="{ 'has-tabs': tabs }">
    <article class="tabs" :class="tabClass" v-if="tabs">
      <ul>
        <li @click="setTab('write')" :class="tabActiveClass('write')"><a>{{ writeTabTitle }}</a></li>
        <li @click="setTab('preview')" :class="tabActiveClass('preview')"><a>{{ previewTabTitle }}</a></li>
      </ul>
    </article>
    <article class="editor-control" v-if="tab === 'write'">
      <text-area
        class="is-marginless"
        v-bind="{ autogrow, value, error, placeholder, rows, maxRows }"
        @input="input"
        :label="false"
        :classes="classes"/>
    </article>
    <content-wrapper v-else>
      <markdown class="markdown-editor-preview" :source="value || defaultPreview"/>
    </content-wrapper>
  </section>
</template>

<script>
import Markdown from 'vue-markdown-it/dist/vue-markdown-it.common.js'

export default {

  components: {
    Markdown
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean|String|Array,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    autogrow: {
      type: Boolean,
      default: true
    },
    tabs: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String|Array|Object,
      default: () => ({ })
    },
    tabClass: {
      type: String|Array|Object,
      default: 'is-toggle is-small'
    },
    placeholder: {
      type: Boolean|String,
      default: false
    },
    defaultPreview: {
      type: String,
      default: 'Nothing to preview.'
    },
    rows: {
      type: String,
      default: "1"
    },
    maxRows: {
      type: Number,
      default: 0
    },
    writeTabTitle: {
      type: String,
      default: 'Write'
    },
    previewTabTitle: {
      type: String,
      default: 'Preview'
    }
  },

  data: () => ({
    tab: 'write'
  }),

  methods: {
    input(value) {
      this.$emit('input', value)
    },

    setTab(tab) {
      this.tab = tab
    }
  },

  computed: {
    tabActiveClass() {
      return tab => ({
        'is-active': this.tab === tab
      })
    }
  }

}
</script>
