<template>
  <div class="help" :class="[errorClass]">
    <icon
      v-if="errorSymbol"
      :icon="errorSymbol"
      class="is-small"
      :type="iconType"/>
    <span>{{ errorOutput }}</span>
  </div>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.error,
  ],

  props: {
    iconType: {
      type: String,
      default: 'fas',
    },
  },

  computed: {
    errorOutput() {
      return Boolean(this.error instanceof Array) ? this.error[0] : this.error
    }
  }
}
</script>
