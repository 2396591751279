<template>
    <div class="field">
      <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
        <slot/>
      </field-label>
      <p v-if="description" :class="['field-description', descriptionClass]">{{ description }}</p>
      <div class="field is-marginless" :class="{ 'has-addons': $scopedSlots.left || $scopedSlots.right }">
        <div class="control" v-if="$scopedSlots.left"><slot name="left"/></div>
        <div class="control is-expanded" :class="controlClass">
          <dropdown-wrapper
            @active="sweep"
            v-bind="{ disabled, position }"
            class="selector-wrapper"
            ref="dropdown">
  
            <template slot="trigger" slot-scope="{ isActive, isFlipped }">
              <template v-if="isSearchableOrTaggable && !multiple">
                <input
                  v-bind="{ disabled, autofocus, autocomplete, role }"
                  v-model="search"
                  type="text"
                  @focus="$event.target.select()"
                  @keydown="handler"
                  class="selector input"
                  :placeholder="promptLabel"
                  :class="[{ 'is-active': isActive, 'is-flipped': isFlipped }, inputClass]"/>
                <icon v-if="leftIcon"
                  :class="['is-left', iconClass]"
                  :pack="leftIconPack"
                  :type="leftIconType"
                  :icon="leftIcon"/>
                <icon v-if="rightIcon && isSearchableOrTaggable && !working"
                  :class="['is-right', iconClass]"
                  :pack="rightIconPack"
                  :type="rightIconType"
                  :icon="rightIcon"/>
              </template>
  
              <div v-else-if="!multiple"
                class="selector input select"
                v-bind="{ disabled }"
                :class="[{ 'is-active': isActive, 'is-flipped': isFlipped }, inputClass]">
                <span class="data-label">{{ selectedLabel || promptLabel }}</span>
                <icon v-if="leftIcon"
                  :class="['is-left', iconClass]"
                  :pack="leftIconPack"
                  :type="leftIconType"
                  :icon="leftIcon"/>
                <icon v-if="rightIcon && isSearchableOrTaggable && !working"
                  :class="['is-right', iconClass]"
                  :pack="rightIconPack"
                  :type="rightIconType"
                  :icon="rightIcon"/>
              </div>
  
              <div v-else
                @click="isSearchableOrTaggable ? $refs.multiSearch.focus() : null"
                class="selector input select has-children"
                v-bind="{ disabled }"
                :class="[{ 'is-active': isActive, 'is-flipped': isFlipped, 'searchable': isSearchableOrTaggable }, inputClass]">
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control" v-for="item in selected" :key="item[valueKey]">
                    <div class="tags has-addons">
                      <span @click.stop="deleteButtons ? $refs.dropdown.toggle() : deleteItem(item)"
                        :class="['tag', tagClass, {'is-danger': !isSelectable(item)}]">
                        {{ item[labelKey] }}
                      </span>
                      <a v-if="deleteButtons"
                        class="tag is-delete"
                        :class="deleteButtonClass"
                        @click.stop="deleteItem(item)"/>
                    </div>
                  </div>
                  <div class="control">
                    <input v-if="isSearchableOrTaggable"
                      v-autowidth
                      role="presentation"
                      autocapitalize="off"
                      v-bind="{ autofocus, autocomplete, role }"
                      ref="multiSearch"
                      v-model="search"
                      type="text"
                      @focus="$event.target.select()"
                      @blur="blurMultisearch"
                      @keydown="handler"
                      :placeholder="searchLabel"/>
                  </div>
                  <icon v-if="leftIcon"
                    :class="['is-left', iconClass]"
                    :pack="leftIconPack"
                    :type="leftIconType"
                    :icon="leftIcon"/>
                  <icon v-if="rightIcon && isSearchableOrTaggable && !working"
                    :class="['is-right', iconClass]"
                    :pack="rightIconPack"
                    :type="rightIconType"
                    :icon="rightIcon"/>
                  <span v-if="multiple && !selected.length && !isSearchableOrTaggable"
                    class="has-text-grey">
                    {{ promptLabel }}
                  </span>
                </div>
              </div>
            </template>

            <dropdown-item
              class="is-sticky">
              {{ working ? loadingItemsText : emptyText }}
            </dropdown-item>
  
            <template v-if="computedItems.length">
              <dropdown-item v-for="item in computedItems" :key="item[valueKey]"
                tabindex="0"
                @click="handleSelection(item)"
                :class="{
                  'is-active': item[valueKey] === selected,
                  'is-highlighted': multiple && isSelected(item)
                }">
                <slot v-if="$scopedSlots.item" name="item" v-bind="{ item }"/>
                <span v-else class="item-label">{{ item[labelKey] }}</span>
              </dropdown-item>
            </template>
  
            <dropdown-item v-else-if="taggable && search.length"
              class="is-tagger"
              @click="emitNewTag">
              <span>{{ search }}</span>
              <span>{{ newTagLabel }}</span>
            </dropdown-item>
  
            
          </dropdown-wrapper>
        </div>
        <div class="control" v-if="$scopedSlots.right"><slot name="right"/></div>
      </div>
      <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
    </div>
  </template>
  
  <script>
  import { slug } from '../../utils/string'
  import * as computed from '../mixins/computed'
  import * as props from '../mixins/props'
  import debounce from 'lodash/debounce'
  import differenceBy from 'lodash/differenceBy'
  import find from 'lodash/find'
  import map from 'lodash/map'
  import Search from '../../utils/search'
  
  export default {
  
    mixins: [
      props.autocomplete,
      props.autofocus,
      props.classes,
      props.debouncable,
      props.describable,
      props.disabled,
      props.error,
      props.fullWidth,
      props.hintable,
      props.iconable,
      props.items,
      props.label,
      props.required,
      props.role,
      props.working,
  
      computed.hasError
    ],
  
    props: {
      value: {
        default: '',
      },
      position: {
        type: String,
        default: 'bottom-start'
      },
      promptLabel: {
        type: String,
        default: 'Select',
      },
      searchLabel: {
        type: String,
        default: '',
      },
      emptyText: {
        type: String,
        default: 'Start typing to search...'
      },
      loadingItems: {
        type: Boolean,
        default: false,
      },
      loadingItemsText: {
        type: String,
        default: 'Loading items…'
      },
      searchable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      deleteButtons: {
        type: Boolean,
        default: true,
      },
      showSelected: {
        type: Boolean,
        default: false,
      },
      tagClass: {
        type: Array | String | Object,
        default: 'is-light'
      },
      deleteButtonClass: {
        type: Array | String | Object,
      },
      async: {
        type: Boolean,
        default: false
      },
      taggable: {
        type: Boolean,
        default: false
      },
      newTagLabel: {
        type: String,
        default: 'Add this as a new tag'
      },
      clearSelectionOnEmpty: {
        type: Boolean,
        default: true
      },
      onType: {
        type: Function,
        default() {}
      },
      onLoseFocus: {
        type: Function,
        default() {}
      },
      leftIconPack: {
        default: 'fa',
      },
      rightIconPack: {
        default: 'fa',
      }
    },
  
    data() {
      return {
        selected: '',
        search: '',
        computedItems: [],
        searchDebounce: debounce(this.emitSearch, this.debounce, this.debounceOptions),
        isCommitting: false
      }
    },
  
    computed: {
      controlClass() {
        return [{
          'has-icons-left': this.leftIcon,
          'has-icons-right': this.rightIcon,
          'is-loading': this.working && this.isSearchableOrTaggable,
        }, this.classes]
      },
  
      inputClass() {
        return [{
          [this.errorClass]: this.hasError,
          'is-loading': this.working,
        }, this.classes]
      },
  
      selectedLabel() {
        let item = find(this.cleanedItems, item => item[this.valueKey] == this.selected)
        return item ? item[this.labelKey] : ''
      },
  
      isSearchableOrTaggable() {
        return this.searchable || this.taggable
      },
  
      cleanedItems() {
        return map(this.items, (item, index) => {
          if (!item[this.valueKey]) {
            item[this.valueKey] = this.slugValues
              ? slug(item[this.labelKey])
              : index + 1
          }
          return item
        })
      }
    },
  
    created() {
      this.resetItems()
    },
  
    methods: {
      resetItems() {
        this.computedItems = [ ...this.cleanedItems ]
      },
  
      emitSelected() {
        this.$emit('input', this.selected)
      },
  
      handler(event) {
        let { dropdown } = this.$refs
  
        if (event.keyCode === 13) {
          if (!this.computedItems.length) return
          this.handleSelection(this.computedItems[0])
          dropdown.isActive = false
        } else if (!dropdown.isActive) {
          dropdown.isActive = true
        }
      },
  
      sweep(active) {
        if (active) {
          this.isCommitting = false
          return
        }

        if (this.search !== this.selectedLabel) {
          this.search = this.selectedLabel
          this.resetItems()
          this.onLoseFocus()
        }
      },
  
      blurMultisearch() {
        this.search = ''
      },
  
      handleSelection(item) {
        this.isCommitting = true
  
        if (this.multiple) {
          this.isSelected(item)
            ? this.deleteItem(item)
            : this.selected.push(item)
        } else {
          this.search = item[this.labelKey]
          this.selected = item[this.valueKey]
        }
        this.emitSelected()
      },
  
      isSelectable(item) {
        return this.cleanedItems.find(
          _item => _item[this.valueKey] === item[this.valueKey]
        )
      },
  
      isSelected(item) {
        return this.selected.find(
          selectedItem => selectedItem[this.valueKey] === item[this.valueKey]
        )
      },
  
      emitSearch(search) {
        this.$emit('search', search)
      },
  
      emitNewTag() {
        this.$emit('tag', this.search)
      },
  
      deleteItem(item) {
        let value = item[this.valueKey]
        this.selected.splice(this.selected.findIndex(
          item => item[this.valueKey] === value
        ), 1)
        this.emitSelected()
      }
    },
  
    watch: {
      search(value) {
        this.onType(this.search)
        if (!value && this.clearSelectionOnEmpty && !this.multiple) {
          this.selected = ''
        }
  
        if (!this.isCommitting) {
          this[this.debounce ? 'searchDebounce' : 'emitSearch'](value)
        }
  
        if (this.isSearchableOrTaggable && (this.search === this.selectedLabel || this.async)) {
          this.computedItems = this.cleanedItems
          return
        }
  
        let results
  
        if (this.isSearchableOrTaggable && this.search) {
          let searcher = new Search(this.cleanedItems, [this.labelKey])
          results = this.isSearchableOrTaggable
            ? searcher.search(this.search)
            : cleanedItems
        } else {
          results = this.cleanedItems
        }
  
        let output = results || this.cleanedItems
  
        this.computedItems = this.multiple && !this.showSelected
          ? differenceBy(output, this.selected, this.valueKey)
          : output
  
        this.$refs.dropdown.popper.update()
      },
  
      selected(value) {
        this.search = this.selectedLabel
        this.resetItems()
        this.ready ? this.$emit('input', value) : this.ready = true
      },
  
      items(items) {
        this.computedItems = items
        this.$refs.dropdown.popper.update()
      },
  
      value: {
        immediate: true,
        handler(value) {
          this.selected = value
        }
      }
    }
  }
  </script>
  