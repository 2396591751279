<template>
  <span class="icon" @click="$emit('click')" :key="Math.random()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :class="[{ 'icon-animation-pulse': !exists }, classes, 'feather']"
      :data-name="icon"
      viewBox="0 0 24 24"
      :style="{
        height: `${size}em`,
        width: `${size}em`,
      }"
      stroke="currentColor"
      :stroke-linecap="cap"
      :stroke-linejoin="join"
      :stroke-width="width"
      fill="none"
      role="img"
      aria-hidden="true"
      v-html="content"
    ></svg>
  </span>
</template>

<script>
import * as icons from '@/mixins/icons'
import * as computed from '@/mixins/computed'
import { helpCircle } from '@/utils/icons/feather-icons'

export default {

  inheritAttrs: false,

  mixins: [
    icons.shared,
    icons.feather,
    computed.usesIconLibraries
  ],

  data: () => ({
    useLibrary: 'feather',
    fallback: helpCircle
  }),

}
</script>
