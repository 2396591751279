<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div :class="wrapperClass">
      <div :class="containerClass" v-for="(item, index) in items" :key="index">
        <input
          :class="[classes]"
          :id="camelIdentifier(item[labelKey], true)"
          :value="valueKey ? item[valueKey] : slugIdentifier(item[labelKey])"
          class="is-checkradio"
          type="checkbox"
          v-model="values">
        <label :for="camelIdentifier(item[labelKey], true)">{{ item[labelKey] }}</label>
      </div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'

export default {

  mixins: [
    props.classes,
    props.describable,
    props.error,
    props.hintable,
    props.items,
    props.label,
    props.required,

    computed.hasError,
    computed.identifiesFields,
  ],

  props: {
    type: {
      type: String,
      default: 'fields',
    },
    columnSize: {
      type: String,
      default: 'half'
    },
    columnGap: {
      type: String,
      default: '1'
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    values: [],
  }),

  computed: {
    containerClass() {
      return {
        inline: 'is-inline-block',
        fields: 'field',
        columns: `column is-${this.columnSize}`
      }[this.type]
    },
    wrapperClass() {
      return this.type === 'columns'
        ? `columns is-multiline is-variable is-${this.columnGap}`
        : false
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.values = value
      }
    },
    values: {
      handler() {
        this.$emit('input', this.values)
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.columns.is-variable {
  --columnGap: 0.75rem;
  margin: calc(-1 * var(--columnGap));

  .column {
    padding: var(--columnGap);
  }

  @for $i from 0 through 8 {
    &.is-#{$i} {
      --columnGap: #{$i * 0.25rem};
    }
  }
}
</style>
