<template>
  <span class="icon" @click="$emit('click')" :key="Math.random()">
    <i :class="iconClass" :data-fa-mask="maskClass" :data-fa-transform="transform" />
  </span>
</template>

<script>
import * as icons from '@/mixins/icons'

export default {

  inheritAttrs: false,

  mixins: [
    icons.shared,
    icons.fontAwesome,
  ],

  computed: {
    iconClass() {
      return this.generateIconClass(this.icon, this.type)
    },
    maskClass() {
      return this.generateIconClass(this.mask, this.maskType)
    },
  },

  methods: {
    generateIconClass(iconClass, iconType) {
      if (!iconClass) return false

      let iconClasses = iconClass
        .split(' ')
        .map(value => `fa-${value}`)
        .join(' ')

      return [
        iconType,
        iconClasses
      ].join(' ')
    }
  }

}
</script>
