<template>
  <component :is="component"
    v-bind="$props"
    :key="Math.random()"
    @click="$emit('click')"/>
</template>

<script>
import * as icons from '@/mixins/icons'

export default {

  mixins: [
    icons.shared,
    icons.fontAwesome,
    icons.zondicons,
    icons.heroicons,
    icons.bytesize,
    icons.feather
  ],

  props: {
    pack: {
      type: Boolean|String,
      default: 'fa',
      validator: value => [
        false,
        'fa',
        'zondicons',
        'heroicons',
        'bytesize',
        'feather',
      ].includes(value)
    }
  },

  data: () => ({
    components: {
      fa: 'fa-icon',
      zondicons: 'zondicon',
      heroicons: 'heroicon',
      bytesize: 'bs-icon',
      feather: 'feather-icon'
    }
  }),

  computed: {
    component() {
      let use = this.pack
      return this.components[use]
    }
  }

}
</script>
