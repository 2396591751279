<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="control" :class="controlClass">
      <div class="select" :class="selectClass">
        <select
          v-model="selected"
          v-bind="{ disabled, multiple, required }">
          <option
            v-if="!multiple && prompt"
            v-text="promptLabel || defaultPrompt"
            value=""
            :disabled="noRevert">
          </option>
          <option
            v-for="(item, index) in items"
            v-text="item[labelKey]"
            :key="index"
            :value="item[valueKey] || computedValue(index, item[labelKey])">
          </option>
        </select>
      </div>
      <icon v-if="!multiple && leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :type="leftIconType" :icon="leftIcon"/>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import { slug } from '@/utils/string'

export default {

  mixins: [
    props.classes,
    props.describable,
    props.disabled,
    props.error,
    props.fullWidth,
    props.hintable,
    props.iconable,
    props.items,
    props.label,
    props.required,
    props.working,

    computed.hasError
  ],

  props: {
    value: {
      default: '',
    },
    prompt: {
      type: Boolean,
      default: true
    },
    promptLabel: {
      type: String,
      default: '– Select –',
    },
    multiple: {
      type: Boolean,
      default: false
    },
    noRevert: {
      type: Boolean,
      default: false
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data() {
    return {
      defaultPrompt: '– Select –',
      selected: this.multiple ? [] : '',
    }
  },

  computed: {
    controlClass() {
      return {
        'has-icons-left': this.leftIcon,
        'has-icons-right': this.rightIcon,
      }
    },
    selectClass() {
      return [{
        [this.errorClass]: this.hasError,
        'is-multiple': this.multiple,
        'is-fullwidth': this.fullWidth,
        'is-loading': this.working,
      }, this.classes]
    },
    computedValue() {
      return (index, label) => this.slugValues ? slug(label) : index + 1
    }
  },

  watch: {
    selected(value) {
      this.$emit('input', value)
    },
    value: {
      immediate: true,
      handler(value) {
        this.selected = value
      }
    },
  }

}
</script>
