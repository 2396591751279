<template>
  <button class="button is-static">
    <icon
      v-if="leftIcon"
      :class="iconClass"
      :pack="leftIconPack"
      :icon="leftIcon"
      :type="leftIconType"
    />
    <slot v-if="!leftIcon && !rightIcon"/>
    <span v-else><slot/></span>
    <icon
      v-if="rightIcon"
      :class="iconClass"
      :pack="rightIconPack"
      :icon="rightIcon"
      :type="rightIconType"
    />
  </button>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.iconable,
  ],

  props: {
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

}
</script>
