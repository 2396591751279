<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="field is-marginless" :class="{ 'has-addons': $slots.left || $slots.right }">
      <div class="control" v-if="$slots.left"><slot name="left"/></div>
      <div class="control is-expanded" :class="controlClass">
        <input
          :class="[{ [errorClass]: hasError }, classes]"
          @blur="emit"
          @focus="emit"
          v-bind="{ autocomplete, autofocus, dir, disabled, id, name, placeholder, readonly, required, role, type }"
          class="input"
          ref="control">
        <icon v-if="leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :type="leftIconType" :icon="leftIcon"/>
        <icon v-if="rightIcon" :class="['is-right', iconClass]" :pack="rightIconPack" :type="rightIconType" :icon="rightIcon"/>
      </div>
      <div class="control" v-if="$slots.right"><slot name="right"/></div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import formatter from 'cleave.js'
import debounce from 'lodash/debounce'

export default {

  mixins: [
    props.autocomplete,
    props.autofocus,
    props.classes,
    props.debouncable,
    props.describable,
    props.dir,
    props.disabled,
    props.error,
    props.hintable,
    props.iconable,
    props.id,
    props.label,
    props.name,
    props.placeholder,
    props.readonly,
    props.required,
    props.role,
    props.working,

    computed.hasError,
  ],

  props: {
    value: {
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    options: {
      type: Object,
      required: true,
    },
    emitRaw: {
      type: Boolean,
      default: true,
    },
    watchValue: {
      type: Boolean,
      default: false,
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data() {
    return {
      control: null,
      formatter: null,
      emitDebounce: debounce(this.emitEvent, this.debounce, this.debounceOptions),
    }
  },

  computed: {
    controlClass() {
      return [{
        'has-icons-left': this.leftIcon,
        'has-icons-right': this.rightIcon,
        'is-loading': this.working
      }, this.classes]
    },
  },

  mounted() {
    this.control = this.$refs.control
    this.init()
  },

  beforeDestroy() {
    this.formatter.destroy()
  },

  watch: {
    options: {
      deep: true,
      handler (options) {
        this.formatter.destroy()
        this.formatter = new formatter(this.control, options)
      }
    },
    value(value) {
      // This is only being used in the NumberInput
      // due to a Cleave flaw that moves the cursor
      // to the left of a post-delimiter whilst typing.
      this.watchValue && this.formatter.setRawValue(value)
    }
  },

  methods: {
    init() {
      this.control.value = this.value
      this.formatter = new formatter(this.control, this.options)
      this.options.maxLength && this.control.setAttribute('maxlength', this.options.maxLength)
      if (this.formatter.properties && this.formatter.properties.hasOwnProperty('result')) {
        this.$watch('formatter.properties.result', this.emit)
      } else {
        this.control.addEventListener('input', this.emit)
      }
    },
    emittable() {
      return this.emitRaw ? this.formatter.getRawValue() : this.control.value
    },
    emitEvent(event) {
      this.$emit(event.type, this.emittable())
      if (event.type === 'input' && !this.emitRaw) {
        this.$emit('raw', this.formatter.getRawValue())
      }
    },
    emit(event) {
      if (typeof event === 'string') {
        event = { type: 'input' }
      }
      let method = this.debounce && event.type === 'input' ? 'emitDebounce' : 'emitEvent'
      this[method](event)
    },
  }

}
</script>
