<template>
  <formatted-text-input
    v-bind="{ autocomplete, autofocus, classes, disabled, debounce, debounceOptions, error, errorClass, errorSymbol, iconClass, id, label, leftIcon, leftIconType, leftIconPack, name, placeholder, readonly, required, requiredClass, requiredText, hint, hintIcon, hintClass, description, rightIcon, rightIconType, rightIconPack, role, working }"
    v-model="amount"
    type="tel"
    @blur="clean"
    :options="options"
    :watch-value="true">
    <slot/>
  </formatted-text-input>
</template>

<script>
import * as props from '@/mixins/props'
import Big from 'big.js'

export default {

  mixins: [
    props.autofocus,
    props.autocomplete,
    props.bounds,
    props.classes,
    props.debouncable,
    props.describable,
    props.disabled,
    props.error,
    props.hintable,
    props.iconable,
    props.id,
    props.label,
    props.name,
    props.placeholder,
    props.readonly,
    props.required,
    props.role,
    props.working,
  ],

  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    precision: {
      type: Number,
      default: 2
    },
    asString: {
      type: Boolean,
      default: true,
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data: () => ({
    amount: '',
  }),

  computed: {
    options() {
      return {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: this.precision,
      }
    }
  },

  methods: {
    output(input) {
      input = (input === '' || input === '-') ? '0' : input
      let output = Big(input).toFixed()
      return (this.asString) ? output : parseFloat(output)
    },
    clean() {
      let float = parseFloat(this.output(this.value)),
          value = this.value
      if (float > this.max) value = this.max
      if (float < this.min) value = this.min
      this.amount = this.output(value)
    }
  },

  mounted() {
    this.clean()
  },

  watch: {
    value(value) {
      this.amount = this.output(value)
    },
    amount() {
      this.$emit('input', this.output(this.amount))
    }
  }
}
</script>
