<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="control" :class="controlClass">
      <textarea
        ref="control"
        v-bind="{ autofocus, disabled, dir, id, name, placeholder, readonly, required, role, value, maxlength, rows }"
        :class="[{ [errorClass]: hasError }, classes]"
        :required="required"
        @blur="emit"
        @focus="emit"
        @input="emit"
        @keydown.enter="limitRows"
        class="textarea">
      </textarea>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import autosize from '@/utils/autosize'

export default {

  mixins: [
    props.autofocus,
    props.classes,
    props.describable,
    props.dir,
    props.disabled,
    props.error,
    props.hintable,
    props.id,
    props.label,
    props.name,
    props.placeholder,
    props.readonly,
    props.required,
    props.role,
    props.working,

    computed.hasError,
  ],

  props: {
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Boolean|Number|String,
      default: false,
    },
    rows: {
      type: Boolean|Number|String,
      default: false,
    },
    maxRows: {
      type: Number,
      default: 0
    },
    autogrow: Boolean,
  },

  computed: {
    controlClass() {
      return [{
        'is-loading': this.working
      }, this.classes]
    },
  },

  mounted() {
    if (this.autogrow) autosize(this.$refs.control)
  },

  methods: {
    emit(event) {
      this.$emit(event.type, event.target.value)
    },
    limitRows(event) {
      if (this.maxRows && event.target.value.split("\n").length >= this.maxRows) {
        event.preventDefault()
        this.$emit('maxrows', this.maxRows)
        return false
      }
    }
  }
}
</script>
