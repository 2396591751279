<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <input
      :class="[classes]"
      :id="fieldIdentifier"
      :checked="value"
      :disabled="disabled"
      class="switch"
      type="checkbox">
    <label v-html="innerLabel" @click="updateInput"></label>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'

export default {

  mixins: [
    props.classes,
    props.describable,
    props.disabled,
    props.fieldId,
    props.hintable,
    props.innerLabel,
    props.label,
    props.required,

    computed.identifiesFields,
  ],

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  methods: {
    updateInput() {
      !this.disabled && this.$emit('input', !this.value)
    }
  }
}
</script>
