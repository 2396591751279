<template>
  <action-button
    v-bind="{ badge, iconClass, leftIcon, leftIconType, leftIconPack, rightIcon, rightIconType, rightIconPack, working }"
    @click="submit"
    class="is-primary"
    type="submit">
    <slot/>
  </action-button>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.badge,
    props.iconable,
    props.working,
  ],

  props: {
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  methods: {
    submit() {
      this.$emit('click')
      this.$emit('submit')
    }
  },

}
</script>
